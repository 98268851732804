export const JournalTimePeriod = [
  { id: "EARLY_MORNING", name: "EARLY  MORNING:  5.00-8.00" },
  { id: "MORNING", name: "MORNING:  8.00-11.00" },
  { id: "NOON", name: "NOON:  11.00-14.00" },
  { id: "AFTERNOON", name: "AFTERNOON:  14.00-17.00" },
  { id: "EVENING", name: "EVENING:  17.00-20.00" },
  { id: "LATE_EVENING", name: "LATE  EVENING:  20.00-23.00" },
  { id: "NIGHT", name: "NIGHT:  23.00-5.00" },
];

export const AllDayTimePeriod = [{ id: "ALL_DAY", name: "ALL  DAY:  5.00-00.00" }];
