import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FC, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useGetOne,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { CustomDataProvider } from "../../../auth";
import { Patient, Practitioner } from "../../types";
import { Reason, ReasonChoices } from "../assignPatToMhpChoices";

export const AssignToPractitionerButton: FC = () => {
  const [open, setOpen] = useState(false);
  const { institutionId, practitionerId } = useParams();
  const { id, firstName, lastName } = useRecordContext<Patient>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();
  const [reason, setReason] = useState<Reason | undefined>();
  const [isReasonError, setIsReasonError] = useState(false);

  const { mutateAsync } = useMutation(() =>
    dataProvider.assignPatientToPractitioner(
      institutionId,
      practitionerId,
      id,
      reason
    )
  );

  const practitioner = useGetOne<Practitioner>(`users/practitioners`, {
    id: practitionerId,
  }).data;

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    if (!reason) {
      setIsReasonError(true);

      return;
    }
    await mutateAsync();
    refresh();
  };

  return (
    <>
      <ReactAdminButton
        label="Assign"
        onClick={(e) => {
          e.stopPropagation();
          void handleClick();
        }}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {`Are you sure you want to assign ${firstName} ${lastName} to ${
            practitioner ? practitioner.firstName : ""
          } ${practitioner ? practitioner.lastName : ""}?`}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="reason-label">Reason for assigning</InputLabel>
            <Select<Reason>
              autoFocus
              value={reason}
              onChange={({ target: { value } }) => {
                setReason(value as Reason);
                setIsReasonError(false);
              }}
              variant="standard"
              label="Reason for assigning"
              id="reason"
              labelId="reason-label"
              required
              error={isReasonError}
            >
              {ReasonChoices.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ReactAdminButton
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleDialogClose();
            }}
          />
          <ReactAdminButton
            label="Confirm"
            onClick={(e) => {
              e.stopPropagation();
              void handleConfirm();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
