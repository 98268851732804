export enum Reason {
  CASE_MANAGEMENT = 'CASE_MANAGEMENT',
  REFERENCE_PERSON = 'REFERENCE_PERSON',
  GROUP_LEADER = 'GROUP_LEADER',
  SUPERVISION = 'SUPERVISION',
  VACATION_REPLACEMENT = 'VACATION_REPLACEMENT',
  EMERGENCY = 'EMERGENCY'
}

export const ReasonChoices = Object.values(Reason).map((reason) => ({
  id: reason,
  name: reason
}))