import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { FC } from "react";
import { useInput, Validator } from "react-admin";

import { minGroupSelectWidth } from "./commonStyling";

interface Choice {
  id: string;
  name: string;
}

interface GroupedChoices {
  group: string;
  items: Choice[];
}

interface GroupedSelectInputProps extends SelectProps {
  source: string;
  label: string;
  choices: GroupedChoices[];
  validate?: Validator | Validator[];
}

interface FieldProps {
  name: string;
  onChange: (value: string) => void;
  value: string;
}

interface FieldState {
  error?: {
    message?: string;
  };
}

export const GroupedSelectInput: FC<GroupedSelectInputProps> = ({
  source,
  label,
  choices,
  validate,
  ...props
}) => {
  const inputProps = useInput({ source, validate });

  const field: FieldProps = {
    name: inputProps.field.name,
    onChange: inputProps.field.onChange,
    value: (inputProps.field.value as string) || "",
  };

  const fieldState: FieldState = {
    error: inputProps.fieldState.error,
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    field.onChange(selectedValue);
  };

  return (
    <FormControl
      fullWidth
      error={!!fieldState.error}
      required={inputProps.isRequired}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={field.name}
        value={field.value || ""}
        onChange={handleChange}
        sx={minGroupSelectWidth}
        label={label}
        {...props}
      >
        {choices.flatMap((group) => [
          <ListSubheader key={group.group}>{group.group}</ListSubheader>,
          ...group.items.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              {choice.name}
            </MenuItem>
          )),
        ])}
      </Select>
      {fieldState.error?.message && (
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};
